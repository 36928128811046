<template>
	<div class="home">
		<v-card>
			<v-container fluid>
				<v-row><v-col cols="12"></v-col></v-row>

				<v-row>
					<v-col cols="12">
						<v-card outlined>
							<v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
								<div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
									SEARCH FILTER
								</div>
							</v-card-title>

							<v-row class="pa-4">
								<v-col cols="12" sm="12" md="4" lg="2" xl="2" v-if="brokerMap.length > 1">
									<v-autocomplete label="Filter Broker"
										v-model="selectedBrokerId" :items="brokerMap" item-text="broker" item-value="broker_id"
										auto-select-first chips clearable deletable-chips small-chips></v-autocomplete>
								</v-col>

								<v-col cols="12" sm="12" md="4" lg="2" xl="2">
									<v-datetime-picker label="Start Time" v-model="selectedStartDate"></v-datetime-picker>
								</v-col>

								<v-col cols="12" sm="12" md="4" lg="2" xl="2">
									<v-datetime-picker label="End Time" v-model="selectedEndDate"></v-datetime-picker>
								</v-col>

								<v-col cols="12" sm="12" md="4" lg="6" xl="6">
									<v-autocomplete label="Filter Mode"
										v-model="selectedMode" :items="filterModeList" item-text="text" item-value="value"
										auto-select-first chips clearable deletable-chips small-chips></v-autocomplete>
								</v-col>

								<v-col cols="12" sm="12" md="12" lg="12" xl="12">
									<p>Click to Load Sample Search</p>
									<!-- <v-chip color="primary" class="mr-2 sample-click"
										v-for="item in sampleContent" :key="item.name"
										label outlined>
										{{ item.name }}
									</v-chip> -->
									<v-btn color="primary" class="mr-2 sample-click" v-on:click="sampleContentChange(item)"
										v-for="item in sampleContent" :key="item.name" small outlined>
										{{ item.name }}
									</v-btn>
								</v-col>

								<v-col cols="12" sm="12" md="4" lg="6" xl="6">
									<v-text-field label="Content (Search Any Matched Log)" v-model="selectedContent" hide-details="auto"></v-text-field>
								</v-col>
							</v-row>
							<v-row class="pa-4">
								<v-col cols="4">
									<v-btn v-on:click="serverLogHandler().load()" :loading="btnLoading" color="primary">SEARCH</v-btn>
								</v-col>
							</v-row>

						</v-card>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12">
						<v-card outlined>
							<v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
								<div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
									RESULT
								</div>
							</v-card-title>

							<v-row>
								<v-col cols="12" sm="12" md="12" lg="12" xl="12">
									<DataTableSearch :headers="tableHeader" :itemsPerPage="itemsPerPage" :dataSource="tableData" />
								</v-col>
							</v-row>

						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</div>
</template>
<script>
import * as apiManagerServerLog from "@components/api/pk/manager/server-log";
import Breadcrumbs from "@components/Breadcrumbs";
import DataTableSearch from "@components/search/DataTableSearch";
import { mapState } from "vuex";
import { helper } from "@components/mixins/helper";
import { snackbar } from "@components/mixins/snackbar";
import moment from "moment";

export default {
    components: { Breadcrumbs, DataTableSearch },
    mixins: [helper, snackbar],
    data () {
        return {
            btnLoading: false,
            selectedContent: "",
            selectedBrokerId: -1,
            selectedStartDate: null,
            selectedEndDate: null,
            tableData: [],
            itemsPerPage: 20,
            selectedMode: 0,
            tableHeader: [
                { text: "Code", value: "Code" },
                { text: "Time", value: "Time" },
                { text: "Ip", value: "Ip" },
                { text: "Message", value: "Message" }
            ],
            filterModeList: [
                { text: "LOG_TYPE_STANDARD (A standard request mode. All messages except for user connection notifications are requested.)", value: 0, desc: "" },
                { text: "LOG_TYPE_LOGINS (In this mode, all messages about server connection (CmdLogin) are requested.)", value: 1, desc: "" },
                { text: "LOG_TYPE_TRADES (Messages about trading operations (CmdTrade) are requested in this mode.)", value: 2, desc: "" },
                { text: "LOG_TYPE_ERRORS (Only error messages (CmdErr) are requested in this mode.)", value: 3, desc: "" },
                { text: "LOG_TYPE_FULL (All types of log entries are requested when this mode is selected.)", value: 60, desc: "" }
            ],
            sampleContent: [
                { name: "User Update", value: "update information of '99999981'", filterMode: 0 },
                { name: "Open Order", value: "open order '99999981'", filterMode: 0 },
                { name: "Close Order", value: "close order '99999981'", filterMode: 0 }
            ],
            menu2: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        };
    },
    computed: {
        ...mapState(["brokerMap"])
    },
    methods: {
        /**
		 * 监听sample content变化
		 * @param  {[type]} value [description]
		 * @return {[type]}       [description]
		 */
        sampleContentChange (item) {
            this.selectedContent = item.value;
            this.selectedMode = item.filterMode;
        },
        /**
		 * server log处理事件方法
		 * @return {[type]} [description]
		 */
        serverLogHandler () {
            const self = this;
            return {
                load () {
                    if (self.checkEmptyField(self.selectedContent)) {
                        self.snackBarDanger("Please fill in search content");
                        return;
                    }

                    if (self.checkEmptyField(self.selectedMode)) {
                        self.snackBarDanger("Please select mode");
                        return;
                    }

                    self.btnLoading = true;

                    const params = {
                        broker_id: self.selectedBrokerId,
                        equipment: self.selectedContent,
                        // start_time: self.formatTimestamp(self.selectedStartDate), end_time: self.formatTimestamp(self.selectedEndDate),
                        start_time: moment(self.selectedStartDate).format("YYYY-MM-DD HH:mm:ss"),
                        end_time: moment(self.selectedEndDate).format("YYYY-MM-DD HH:mm:ss"),
                        mode: self.selectedMode
                    };
                    console.log(typeof self.selectedStartDate);
                    console.log(params);

                    apiManagerServerLog.create(params).then(res => {
                        console.log(res);
                        self.btnLoading = false;
                        self.tableData = res.data;
                    });
                }
            };
        }
    },
    mounted () {
        const data = JSON.parse(localStorage.getItem("bp"));
        const brokerArr = data.broker;
        if (brokerArr.length > 1) {
            this.brokers = brokerArr;
            this.selectedBrokerId = 1;
            this.selectedBroker = this.brokers[0].broker;
        }

        const brokerId = data.broker[0].broker_id;
        const broker = data.broker[0].broker;
        this.selectedBrokerId = brokerId;
        this.selectedBroker = broker;

        const currentDate = new Date();
        let dateOffset = (24 * 60 * 60 * 1000) * 1 * 0; // 5 days

        currentDate.setTime(currentDate.getTime() + dateOffset);
        this.selectedEndDate = currentDate;

        dateOffset = (24 * 60 * 60 * 1000) * 2; // 20 days

        const startDate = new Date();

        startDate.setTime(startDate.getTime() - dateOffset);

        this.selectedStartDate = startDate;
    }
};
</script>

<style>
.sample-click:hover {
	cursor: pointer;
}
</style>

<template>
    <div>
        <!-- <v-text-field class="mt-n2 mb-2" v-model="search" append-icon="mdi-search"
            label="Search" single-line hide-details>
        </v-text-field> -->
        <v-data-table dense :search="search" :headers="headers" :items="dataSource" class="elevation-1"
            :items-per-page="itemsPerPage">
    	</v-data-table>
    </div>
</template>

<script>
export default {
    props: {
        headers: null,
        dataSource: null,
        itemsPerPage: null
    },
    data () {
        return {
            search: ""
        };
    }
};
</script>
